export const menu_type = {
  state: () => ({
    menus: [],
    _id: ''
  }),
  getters: {
    get_menu({menus}) {
      return menus
    },
    get_id({_id}) {
      return _id
    }
  },
  mutations: {
    set_menu(state, payload) {
      state.menus = payload
    },
    set_id(state, payload) {
      state._id = payload
    }
  },
  actions: {
    async api_all_menu({commit, dispatch}) {
      const res = await dispatch('getAxios', {
        url: 'menu',
      })
      if (res.status === 200) {
        commit('set_menu', res.data[0]?.menu)
        commit('set_id', res.data[0]?._id)
      }
    },
    async api_post_menu({commit, dispatch}, payload) {
      const res = await dispatch('postAxios', {
        url: 'menu',
        data: payload
      })
      if (res.status === 201) {
        commit('set_menu', res.data[0]?.menu)
        commit('set_id', res.data[0]?._id)
        dispatch('api_all_menu')
      }
    },
    async api_save_menu({commit, dispatch}, payload) {
      const res = await dispatch('putAxios', {
        url: 'menu',
        data: payload
      })
      if (res.status === 201) {
        commit('set_menu', res.data[0]?.menu)
        commit('set_id', res.data[0]?._id)
      }
    }
  }
}