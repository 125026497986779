import {
  ElMessage
} from 'element-plus';

export const category = {
  state: () => ({
    categories: [],
    category: {},
    categoryCount: 0,
    categoryTitle: 'Категории',
  }),
  getters: {
    categoryTitle({title}) {
      return title
    },
    categoryCount(state) {
      return state.categoryCount;
    },
    categories(state) {
      return state.categories;
    },
    category(state) {
      return state.category;
    },
    activeCategorys(state) {
      return state.categories.filter((category) => category.status === true);
    },
  },
  mutations: {
    categoryCount(state, payload) {
      state.categoryCount = payload;
    },
    categories(state, payload) {
      state.categories = payload;
    },
    category(state, payload) {
      state.category = payload;
    },
    newCategory(state, payload) {
      state.categories = [payload, ...state.categories];
      state.categoryCount += 1;
    },
    saveCategory(state, payload) {
      let index = state.categories.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.categories[index] = payload;
      }
    },
    deleteCategory(state, payload) {
      state.categories = state.categories.filter((category) => {
        if (category._id === payload) return false;
        return category;
      });
      state.categoryCount -= 1;
    },
  },
  actions: {

    async getCategorys({commit, dispatch}, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'category',
        search: payload,
      });
      if (res.status === 200) {
        commit('loading', false);
        commit('categories', res.data.category);
        commit('categoryCount', res.data.count);
      }
    },

    async addCategory({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'category',
        data: payload,
      })
    },

    async getCategory({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `category/${payload}`,
      });
    },

    async addLanguage({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'category/translate',
        data: payload
      })
      if (res.status === 201) {
        commit('saveCategory', res.data);
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async apiSaveCategory({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: `category`,
        data: payload,
      })
    },

    async changeStatusCategory({dispatch, commit}, payload) {
      let res = await dispatch('getAxios', {
        url: `category/status/${payload._id}/${payload.status}`,
      });
      if (res.status === 200) {
        commit('saveCategory', res.data);
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    
    async deleteCategory({dispatch, commit}, payload) {
      console.log('ku');
      let res = await dispatch('deleteAxios',
        `category/${payload}`,
      )
      console.log(payload);
      if (res.status == 200) {
        commit('deleteCategory', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
  },
};