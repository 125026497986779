import { createStore } from "vuex";

import { modulesList } from "./modules";

export default createStore({
  state: {
    url: "https://api.trm.uz",
    // url: 'https://dunyo-back.doom.uz',
    mainUrl: "https://api.trm.uz",
    months: [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ],
    loading: false,
    dark: false,
    status: ["Faol", "Faol emas"],
    weekdays: [
      "Dushanba",
      "Seshanba",
      "Chorshanba",
      "Payshanba",
      "Juma",
      "Shanba",
    ],
  },
  getters: {
    mainUrl: ({ mainUrl }) => mainUrl,
    dark(state) {
      return state.dark;
    },
    weekdays(state) {
      return state.weekdays;
    },
    status(state) {
      return state.status;
    },
    loading(state) {
      return state.loading;
    },
    url(state) {
      return state.url;
    },
    months(state) {
      return state.months;
    },
  },
  mutations: {
    dark(state, payload) {
      state.dark = payload;
    },
    loading(state, payload) {
      state.loading = payload;
    },
  },
  modules: {
    modulesList,
  },
});
