import {ElMessage} from 'element-plus';

export const sponsor = {
  state: () => ({
    sponsors: [],
    sponsors_count: 0,
  }),
  getters: {
    sponsors(state) {
      return state.sponsors
    },
    sponsors_count(state) {
      return state.sponsors_count
    },
  },
  mutations: {
    set_sponsors(state, payload) {
      state.sponsors = payload
    },
    set_sponsors_count(state, payload) {
      state.sponsors_count = payload
    },
    new_sponsors(state, payload) {
      state.sponsors = [payload, ...state.sponsors]
      state.sponsors_count += 1
    },
    save_sponsors(state, payload) {
      let index = state.sponsors.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.sponsors[index] = payload
      }
    },
    delete_sponsors(state, payload) {
      state.sponsors = state.sponsors.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.sponsors_count -= 1;
    },
  },
  actions: {

    async api_sponsors({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'sponsors',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_sponsors', res.data.sponsors)
        commit('set_sponsors_count', res.data.count)
      }
    },

    async api_add_sponsors({dispatch}, payload) {
      return await dispatch('postAxios', {
        url: 'sponsors',
        data: payload
      })
    },

    async api_get_sponsors({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `sponsors/${payload}`,
      })
    },

    async api_sponsors_add_lang ({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'sponsors/translate',
        data: payload
      })
      if (res.status === 201) {
        commit('save_sponsors', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },

    async api_save_sponsors({dispatch}, payload) {
      return await dispatch('putAxios', {
        url: 'sponsors',
        data: payload
      })
    },

    async api_sponsors_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `sponsors/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_sponsors', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async api_delete_sponsors({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `sponsors/${payload}`)
      if (res.status === 200) {
        commit('delete_sponsors', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}