import {
  ElMessage
} from 'element-plus';

export const news = {
  state: () => ({
    news: [],
    one_news: {},
    news_count: 0
  }),
  getters: {
    all_news(state) {
      return state.news
    },
    one_news(state) {
      return state.one_news
    },
    news_count(state) {
      return state.news_count
    }
  },
  mutations: {
    set_news (state, payload) {
      state.news = payload
    },
    set_one_news(state, payload) {
      state.one_news = payload
    },
    set_news_count(state, payload) {
      state.news_count = payload
    },
    new_news(state, payload) {
      state.news = [payload, ...state.news]
      state.news_count += 1
    },
    save_news(state, payload) {
      let index = state.news.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.news[index] = payload
      }
    },
    delete_news(state, payload) {
      state.news = state.news.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.news_count -= 1
    }
  },
  actions: {
    async api_news({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'news',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_news', res.data.news)
        commit('set_news_count', res.data.count)
      }
    },

    async api_get_news_sub({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `news/subcategory/${payload}`
      })
      if (res.status === 200) {
        commit('set_subcategories', res.data)
      }
    },
    
    async api_add_news({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: 'news',
        data: payload
      })
      if (res.status === 201) {
        commit('new_news', res.data)
        ElMessage({
          message: `Новость добавлена`,
          type: 'success',
        });
      }
    },

    async api_get_news({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `news/${payload}`
      })
    },

    async api_add_news_lang({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'news/translate',
        data: payload
      })
      commit('save_news', res.data)
      if (res.status === 201) {
        ElMessage({
          message: `Перевод добавлен`,
          type: 'success',
        });
      }
    },

    async api_save_news({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'news',
        data: payload
      })
      if (res.status === 201) {
        commit('save_news', res.data)
        ElMessage({
          message: `Новость обновлена`,
          type: 'success',
        });
      }
    },

    async api_news_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `news/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_news', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async api_news_change_actual({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `news/actual/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_news', res.data)
        ElMessage({
          message: `Актуальность изменена.`,
          type: 'success',
        });
      }
    },

    async api_delete_news({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios', `news/${payload}`)
      if (res.status === 200) {
        commit('delete_news', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
    
    async api_delete_image({dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: `helper/delete/image`, 
        data: payload
      })
      if (res.status === 200) {
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}