import {ElMessage} from 'element-plus';

export const photo_galery = {
  state: () => ({
    photos: [],
    photos_count: 0,
  }),
  getters: {
    photos(state) {
      return state.photos
    },
    photos_count(state) {
      return state.photos_count
    },
  },
  mutations: {
    set_photos(state, payload) {
      state.photos = payload
    },
    set_photos_count(state, payload) {
      state.photos_count = payload
    },
    new_photo(state, payload) {
      state.photos = [payload, ...state.photos]
      state.photos_count += 1
    },
    save_photo(state, payload) {
      let index = state.photos.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.photos[index] = payload
      }
    },
    delete_photos(state, payload) {
      state.photos = state.photos.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.photos_count -= 1;
    },
  },
  actions: {
    async api_photos({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'galery',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_photos', res.data.galery)
        commit('set_photos_count', res.data.count)
      }
    },
    async api_add_photo({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: `galery`,
        data: payload
      })
      if (res.status === 201) {
        commit('new_photo', res.data)
        ElMessage({
          message: `Фото галерея добавлена`,
          type: 'success',
        });
      }
    },
    async api_get_photo({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `galery/${payload}`
      })
    },
    async api_add_photo_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `galery/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_photo', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },
    async api_photo_status_change({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `galery/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_photo', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    async api_save_photo({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'galery',
        data: payload
      })
      if (res.status === 201) {
        commit('save_photo', res.data)
        ElMessage({
          message: `Данные обновлены`,
          type: 'success',
        });
      }
    },
    async api_delete_photo({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `galery/${payload}`)
      if (res.status === 200) {
        commit('delete_photos', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}