import {
  ElMessage
} from 'element-plus';

export const activity = {
  state: () => ({
    activity: [],
    activity_count: 0
  }),
  getters: {
    all_activity(state) {
      return state.activity
    },
    activity_count(state) {
      return state.activity_count
    }
  },
  mutations: {
    set_activity (state, payload) {
      state.activity = payload
    },
    set_activity_count(state, payload) {
      state.activity_count = payload
    },
    new_activity(state, payload) {
      state.activity = [payload, ...state.activity]
      state.activity_count += 1
    },
    save_activity(state, payload) {
      let index = state.activity.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.activity[index] = payload
      }
    },
    delete_activity(state, payload) {
      state.activity = state.activity.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.activity_count -= 1
    }
  },
  actions: {
    async api_activity({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'activity',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_activity', res.data.activity)
        commit('set_activity_count', res.data.count)
      }
    },
    
    async api_add_activity({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: 'activity',
        data: payload
      })
      if (res.status === 201) {
        commit('new_activity', res.data)
        ElMessage({
          message: `Новость добавлена`,
          type: 'success',
        });
      }
    },

    async api_get_activity({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `activity/${payload}`
      })
    },

    async api_add_activity_lang({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'activity/translate',
        data: payload
      })
      commit('save_activity', res.data)
      if (res.status === 201) {
        ElMessage({
          message: `Перевод добавлен`,
          type: 'success',
        });
      }
    },

    async api_save_activity({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'activity',
        data: payload
      })
      if (res.status === 201) {
        commit('save_activity', res.data)
        ElMessage({
          message: `Новость обновлена`,
          type: 'success',
        });
      }
    },

    async api_activity_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `activity/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_activity', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },

    async api_delete_activity({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios', `activity/${payload}`)
      if (res.status === 200) {
        commit('delete_activity', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
  }
}