import {ElMessage} from 'element-plus';

export const open_data = {
  state: () => ({
    doc_datas: [],
    doc_datas_count: 0,
  }),
  getters: {
    doc_datas(state) {
      return state.doc_datas
    },
    doc_datas_count(state) {
      return state.doc_datas_count
    },
  },
  mutations: {
    set_doc_datas(state, payload) {
      state.doc_datas = payload
    },
    set_doc_datas_count(state, payload) {
      state.doc_datas_count = payload
    },
    new_doc_datas(state, payload) {
      state.doc_datas = [payload, ...state.doc_datas]
      state.doc_datas_count += 1
    },
    save_doc_datas(state, payload) {
      let index = state.doc_datas.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.doc_datas[index] = payload
      }
    },
    delete_doc_datas(state, payload) {
      state.doc_datas = state.doc_datas.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.doc_datas_count -= 1;
    },
  },
  actions: {
    async api_doc_datas({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'opendata',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_doc_datas', res.data.open_data)
        commit('set_doc_datas_count', res.data.count)
      }
    },
    async api_add_document({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: `opendata`,
        data: payload
      })
      if (res.status === 201) {
        commit('new_doc_datas', res.data)
        ElMessage({
          message: `Документы добавлены`,
          type: 'success',
        });
      }
    },
    async api_get_doc_data({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `opendata/${payload}`
      })
    },
    async api_add_dats_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `opendata/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_doc_datas', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },
    async api_datas_status_change({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `opendata/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_doc_datas', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    async api_save_doc_data({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'opendata',
        data: payload
      })
      if (res.status === 201) {
        commit('save_doc_datas', res.data)
        ElMessage({
          message: `Данные обновлены`,
          type: 'success',
        });
      }
    },
    async api_delete_doc_data({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `opendata/${payload}`)
      if (res.status === 200) {
        commit('delete_doc_datas', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}