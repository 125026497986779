import { ElMessage } from "element-plus"

export const links = {
  state: () => ({
    links: [],
    links_count: 0
  }),
  getters: {
    get_links({links}) {
      return links
    },
    get_links_count({links_count}) {
      return links_count
    }
  },
  mutations: {
    set_links(state, payload) {
      return state.links = payload
    },
    set_links_count(state, payload) {
      state.links_count = payload
    },
    new_link(state, payload) {
      state.links = [payload, ...state.links]
      state.links_count += 1
    },
    save_link(state, payload) {
      let index = state.links.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.links[index] = payload
      }
    },
    delete_link(state, payload) {
      state.links = state.links.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.links_count -= 1
    }
  },
  actions: {
    async api_get_links({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'link',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_links', res.data.links)
        commit('set_links_count', res.data.count)
      }
    },
    async api_add_link({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: 'link',
        data: payload
      })
      if (res.status === 201) {
        commit('new_link', res.data)
        ElMessage({
          message: `Ссылка добавлена`,
          type: 'success',
        });
      }
    },
    async api_get_link({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `link/${payload}`
      })
    },
    async api_save_link({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'link',
        data: payload
      })
      if (res.status === 201) {
        commit('save_link', res.data)
        ElMessage({
          message: `Ссылка обновлена`,
          type: 'success',
        });
      }
    },
    async api_add_link_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `link/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_link', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },
    async api_link_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `link/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_link', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    async api_delete_link({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios', `link/${payload}`)
      if (res.status === 200) {
        commit('delete_link', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
  }
}