import { ElMessage } from "element-plus";

export const youtube = {
  state: () => ({
    youtubes: [],
    youtubes_count: 0,
  }),
  getters: {
    youtubes(state) {
      return state.youtubes;
    },
    youtubes_count(state) {
      return state.youtubes_count;
    },
  },
  mutations: {
    set_youtubes(state, payload) {
      state.youtubes = payload;
    },
    set_youtubes_count(state, payload) {
      state.youtubes_count = payload;
    },
    new_youtubes(state, payload) {
      state.youtubes = [payload, ...state.youtubes];
      state.youtubes_count += 1;
    },
    save_youtubes(state, payload) {
      let index = state.youtubes.findIndex((val) => val._id == payload._id);
      if (index !== -1) {
        state.youtubes[index] = payload;
      }
    },
    delete_youtubes(state, payload) {
      state.youtubes = state.youtubes.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.youtubes_count -= 1;
    },
  },
  actions: {
    async api_youtubes({ commit, dispatch }, payload) {
      commit("loading", true);
      let res = await dispatch("getAxios", {
        url: "youtube",
        search: payload,
      });
      if (res.status === 200) {
        console.log(res.data);
        commit("loading", false);
        commit("set_youtubes", res.data.links);
        commit("set_youtubes_count", res.data.count);
      }
    },

    async api_add_youtubes({ dispatch }, payload) {
      return await dispatch("postAxios", {
        url: "youtube",
        data: payload,
      });
    },

    async api_get_youtubes({ dispatch }, payload) {
      return await dispatch("getAxios", {
        url: `youtube/${payload}`,
      });
    },

    async api_youtubes_add_lang({ commit, dispatch }, payload) {
      let res = await dispatch("putAxios", {
        url: "youtube/translate",
        data: payload,
      });
      if (res.status === 201) {
        commit("save_youtubes", res.data);
        ElMessage({
          message: `Перевод обновлен`,
          type: "success",
        });
      }
    },

    async api_save_youtubes({ dispatch }, payload) {
      return await dispatch("putAxios", {
        url: "youtube",
        data: payload,
      });
    },

    async api_youtubes_change_status({ commit, dispatch }, payload) {
      let res = await dispatch("getAxios", {
        url: `youtube/status/${payload._id}/${payload.status}`,
      });
      if (res.status === 201) {
        commit("save_youtubes", res.data);
        ElMessage({
          message: `Статус изменён`,
          type: "success",
        });
      }
    },

    async api_delete_youtubes({ commit, dispatch }, payload) {
      let res = await dispatch("deleteAxios", `youtube/${payload}`);
      if (res.status === 200) {
        commit("delete_youtubes", payload);
        ElMessage({
          message: res.data.message,
          type: "success",
        });
      }
    },
  },
};
