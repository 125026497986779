import {layoutApp} from './modules/layout'
import {auth} from './modules/auth'
import {helpers} from './modules/helpers'


import {category} from './data/category'
import {subcategory} from './data/subcategory'
import {news} from './data/news'
import {user} from './data/user'
import {page} from './data/page'
import {workers} from './data/workers'
import {doc_categories} from './data/doc_category'
import {document} from './data/document'
import {photo_galery} from './data/photo_galery'
import {open_data} from './data/open_data'
import {questions} from './data/questions'
import {activity} from './data/activity'
import {sponsor} from './data/sponsor'
import {youtube} from './data/youtube'

//settings
import {languages} from './settings/languages'
import {keywords} from './settings/keyword'
import {menu_type} from './settings/menu_type'
import {links} from './settings/links'
import {menu_pages} from './settings/menu_page'


export const modulesList = {
  modules: {
    layoutApp,
    auth,
    helpers,

    category,
    subcategory,
    news,
    user,
    page,
    workers,
    doc_categories,
    document,
    photo_galery,
    open_data,
    questions,
    activity,
    sponsor,
    youtube,

    // settings
    languages,
    keywords,
    menu_type,
    links,
    menu_pages
  }
}