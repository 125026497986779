import { ElMessage } from "element-plus"

export const questions = {
  state: () => ({
    questions: [],
    questions_count: 0
  }),
  getters: {
    get_questions({questions}) {
      return questions
    },
    get_questions_count({questions_count}) {
      return questions_count
    }
  },
  mutations: {
    set_questions(state, payload) {
      return state.questions = payload
    },
    set_questions_count(state, payload) {
      state.questions_count = payload
    },
    new_question(state, payload) {
      state.questions = [payload, ...state.questions]
      state.questions_count += 1
    },
    save_question(state, payload) {
      let index = state.questions.findIndex(val => val._id === payload._id)
      if (index !== -1) {
        state.questions[index] = payload
      }
    },
    delete_question(state, payload) {
      state.questions = state.questions.filter(val => {
        if (val._id === payload) return false
        return val
      })
      state.questions_count -= 1
    }
  },
  actions: {
    async api_get_questions({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'questions',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_questions', res.data.questions)
        commit('set_questions_count', res.data.count)
      }
    },
    async api_add_question({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: 'questions',
        data: payload
      })
      if (res.status === 201) {
        commit('new_question', res.data)
        ElMessage({
          message: `Ссылка добавлена`,
          type: 'success',
        });
      }
    },
    async api_get_question({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `questions/${payload}`
      })
    },
    async api_save_question({dispatch, commit}, payload) {
      let res = await dispatch('putAxios', {
        url: 'questions',
        data: payload
      })
      if (res.status === 201) {
        commit('save_question', res.data)
        ElMessage({
          message: `Ссылка обновлена`,
          type: 'success',
        });
      }
    },
    async api_add_question_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `questions/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_question', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },
    async api_question_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `questions/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_question', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    async api_delete_question({dispatch, commit}, payload) {
      let res = await dispatch('deleteAxios', `questions/${payload}`)
      if (res.status === 200) {
        commit('delete_question', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    },
  }
}