<template>
  <h3 class="mb-20">Главная страница</h3>
  <!-- <by-region :data="statistic" /> -->
</template>

<script>
// import ByRegion from '@/components/statistic/ByRegion.vue';

export default {
  components: {
    // ByRegion
  },
  computed: {
    
  },
  methods: {
    
  },
  
}
</script>

<style>

</style>