export const adminSettings = [
  {
    path: 'languages',
    icon: 'List',
    name: 'languages',
    title: 'Языки',
    component: () => import(/* webpackChunkName: "languages" */ '../views/settings/LanguagesPage.vue')
  },
  // {
  //   path: 'keywords',
  //   icon: 'Document',
  //   name: 'keywords',
  //   title: 'Ключеве слова',
  //   component: () => import(/* webpackChunkName: "languages" */ '../views/settings/KeywordsPage.vue')
  // },
  {
    path: 'link',
    icon: 'Link',
    name: 'links',
    title: 'Сслыки',
    component: () => import(/* webpackChunkName: "languages" */ '../views/settings/LinksPage.vue')
  },
  {
    path: 'sortable',
    icon: 'Grid',
    name: 'sortable',
    title: 'Сортировка меню',
    component: () => import(/* webpackChunkName: "languages" */ '../views/settings/SortablePage.vue')
  },
  // {
  //   path: 'page-menu',
  //   icon: 'Menu',
  //   name: 'menu-page',
  //   title: 'Меню',
  //   component: () => import(/* webpackChunkName: "languages" */ '../views/settings/MenuPages.vue')
  // },
]

export const adminMenu = [
  {
    path: '/category',
    icon: 'files',
    name: 'categories',
    title: 'Категории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/CategoryPage.vue')
  },
  {
    path: '/subcategory',
    icon: 'Collection',
    name: 'subcategories',
    title: 'Субкатегории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/SubcategoryPage.vue')
  },
  {
    path: '/news',
    icon: 'List',
    name: 'news',
    title: 'Новости',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/NewsPage.vue')
  },
  {
    path: '/pages',
    icon: 'DocumentAdd',
    name: 'page',
    title: 'Страницы',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/PagesPage.vue')
  },
  {
    path: '/workers',
    icon: 'UserFilled',
    name: 'worker',
    title: 'Сотрудники',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/WorkersPage.vue')
  },
  {
    path: '/doccategory',
    icon: 'Ticket',
    name: 'doc_category',
    title: 'Категория ссылок',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/DocCategoryPage.vue')
  },
  {
    path: '/docs',
    icon: 'Connection',
    name: 'document',
    title: 'Полезные ссылки',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/DocumentPage.vue')
  },
  {
    path: '/galery',
    icon: 'PictureFilled',
    name: 'galery',
    title: 'Фото галерея',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/GaleryPage.vue')
  },
  {
    path: '/datas',
    icon: 'Document',
    name: 'open_data',
    title: 'Документы',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/OpenDataPage.vue')
  },
  {
    path: '/question',
    icon: 'QuestionFilled',
    name: 'question',
    title: 'Вопрос ответы',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/QuestionsPage.vue')
  },
  {
    path: '/activity',
    icon: 'Guide',
    name: 'activity',
    title: 'Деятельность',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/ActivityPage.vue')
  },
  {
    path: '/sponsor',
    icon: 'OfficeBuilding',
    name: 'sponsor',
    title: 'Спонсоры',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/SponsorsPage.vue')
  },
  {
    path: '/youtube',
    icon: 'OfficeBuilding',
    name: 'youtube',
    title: 'Youtube',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/YoutubePage.vue')
  },
];

export const limitedMenu = [
  {
    path: '/category',
    icon: 'files',
    name: 'categories',
    title: 'Категории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/CategoryPage.vue')
  },
  {
    path: '/subcategory',
    icon: 'Collection',
    name: 'subcategories',
    title: 'Субкатегории',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/SubcategoryPage.vue')
  },
  {
    path: '/news',
    icon: 'List',
    name: 'news',
    title: 'Новости',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/NewsPage.vue')
  },
];

export const adminLinks = [
  ...adminMenu,
]