import {ElMessage} from 'element-plus';

export const workers = {
  state: () => ({
    workers: [],
    workers_count: 0,
  }),
  getters: {
    workers(state) {
      return state.workers
    },
    workers_count(state) {
      return state.workers_count
    },
  },
  mutations: {
    set_workers(state, payload) {
      state.workers = payload
    },
    set_workers_sount(state, payload) {
      state.workers_count = payload
    },
    new_worker(state, payload) {
      state.workers = [payload, ...state.workers]
      state.workers_count += 1
    },
    save_worker(state, payload) {
      let index = state.workers.findIndex(val => val._id == payload._id)
      if (index !== -1) {
        state.workers[index] = payload
      }
    },
    delete_worker(state, payload) {
      state.workers = state.workers.filter((val) => {
        if (val._id == payload) return false;
        return val;
      });
      state.workers_count -= 1;
    },
  },
  actions: {
    async api_workers({commit, dispatch}, payload) {
      commit('loading', true)
      let res = await dispatch('getAxios', {
        url: 'worker',
        search: payload
      })
      if (res.status === 200) {
        commit('loading', false)
        commit('set_workers', res.data.workers)
        commit('set_workers_sount', res.data.count)
      }
    },
    async api_add_worker({commit, dispatch}, payload) {
      let res = await dispatch('postAxios', {
        url: `worker`,
        data: payload
      })
      if (res.status === 201) {
        commit('new_worker', res.data)
        ElMessage({
          message: `Работник добавлен`,
          type: 'success',
        });
      }
    },
    async api_get_worker({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `worker/${payload}`
      })
    },
    async api_add_worker_lang({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: `worker/translate`,
        data: payload
      })
      if (res.status === 201) {
        commit('save_worker', res.data)
        ElMessage({
          message: `Перевод обновлен`,
          type: 'success',
        });
      }
    },
    async api_worker_change_status({commit, dispatch}, payload) {
      let res = await dispatch('getAxios', {
        url: `worker/status/${payload._id}/${payload.status}`
      })
      if (res.status === 201) {
        commit('save_worker', res.data)
        ElMessage({
          message: `Статус изменён`,
          type: 'success',
        });
      }
    },
    async api_save_worker({commit, dispatch}, payload) {
      let res = await dispatch('putAxios', {
        url: 'worker',
        data: payload
      })
      if (res.status === 201) {
        commit('save_worker', res.data)
        ElMessage({
          message: `Данные обновлены`,
          type: 'success',
        });
      }
    },
    async api_delete_worker({commit, dispatch}, payload) {
      let res = await dispatch('deleteAxios', `worker/${payload}`)
      if (res.status === 200) {
        commit('delete_worker', payload)
        ElMessage({
          message: res.data.message,
          type: 'success',
        });
      }
    }
  }
}